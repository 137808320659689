import React from "react";
import OwlCarousel from 'react-owl-carousel';

function Header() {
  return (
    <header className="header slider-fade">
      {/* <div className="owl-carousel owl-theme"> */}
        {/* The opacity on the image is made with "data-overlay-dark="number". You can change it using the numbers 0-9. */}
        <OwlCarousel  className="owl-theme" nav loop startPosition={0} dots={false} center autoplay autoplayTimeout={5000} items={1}>
        <div
          className="text-center item bg-img"
          data-overlay-dark={2}
          // data-background={require("../assets/images/banners/sojourner_front.webp")}
          style={{background:`url(${require("../assets/images/banners/sojourner_front.webp")})`, backgroundRepeat:"no-repeat", backgroundSize:"cover", backgroundPosition:"center"}}
        >
          <div className="v-middle caption">
            <div className="container">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <h4>Luxury Hotel &amp; Accommodation</h4>
                  <h1>Enjoy a Luxury Experience</h1>
                  <div className="butn-light mt-30 mb-30">
                    {" "}
                    <a href="#book-now">
                      <span>Book Now</span>
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
         <div
          className="text-center item bg-img"
          data-overlay-dark={2}
          // data-background={require("../assets/images/banners/reventon_front.webp")}
          style={{background:`url(${require("../assets/images/banners/reventon_front.webp")})`, backgroundRepeat:"no-repeat", backgroundSize:"cover", backgroundPosition:"center"}}
        >
          <div className="v-middle caption">
            <div className="container">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  {/* <span>
                    <i className="star-rating" />
                    <i className="star-rating" />
                    <i className="star-rating" />
                    <i className="star-rating" />
                    <i className="star-rating" />
                  </span> */}
                  <h4>Unique Place to Relax &amp; Enjoy</h4>
                  <h1>The Perfect Base For You</h1>
                  <div className="butn-light mt-30 mb-30">
                    {" "}
                    <a href="#book-now">
                      <span>Book Now</span>
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div
          className="text-center item bg-img"
          data-overlay-dark={2}
          // data-background={require("../assets/images/banners/reventon_front.webp")}
          style={{background:`url(${require("../assets/images/banners/jersey_front.webp")})`, backgroundRepeat:"no-repeat", backgroundSize:"cover", backgroundPosition:"center"}}
        >
          <div className="v-middle caption">
            <div className="container">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <h4>Unique Place to Relax &amp; Enjoy</h4>
                  <h1>Enjoy a Luxury Experience</h1>
                  <div className="butn-light mt-30 mb-30">
                    {" "}
                    <a href="#book-now">
                      <span>Book Now</span>
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="text-center item bg-img"
          data-overlay-dark={2}
          // data-background={require("../assets/images/banners/genesis_park_front.webp")}
          style={{background:`url(${require("../assets/images/banners/genesis_park_front.webp")})`, backgroundRepeat:"no-repeat", backgroundSize:"cover", backgroundPosition:"center"}}
        >
          <div className="v-middle caption">
            <div className="container">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  {/* <span>
                    <i className="star-rating" />
                    <i className="star-rating" />
                    <i className="star-rating" />
                    <i className="star-rating" />
                    <i className="star-rating" />
                  </span> */}
                  <h4>The Ultimate Luxury Experience</h4>
                  <h1>Enjoy The Best Moments of Life</h1>
                  <div className="butn-light mt-30 mb-30">
                    {" "}
                    <a href="#book-now">
                      <span>Book Now</span>
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        </OwlCarousel>

      {/* </div> */}
      {/* slider reservation */}
      <div className="reservation">
        <a href="tel:08050499247">
          <div className="icon d-flex justify-content-center align-items-center">
            <i className="flaticon-call" />
          </div>
          <div className="call">
            <span>07079484896</span> <br /> <span>08050499247</span> <br />
            Reservation
          </div>
        </a>
      </div>
    </header>
  );
}

export default Header;
